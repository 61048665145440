/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { inject, IReactComponent, observer } from 'mobx-react';
import classNames from 'classnames';

import { Loader } from '../../components/loader';
import { IWithBrandingController } from './controller';

import './styles.scss';

export interface IWithBrandingProps {
    ctrl: IWithBrandingController
}

export function brandingWrapper(mapStoresToProps: any) {
    return (Component: any) => {
        @observer
        class WithBranding extends React.Component< IWithBrandingProps > {
            componentDidMount() {
                this.props.ctrl.getBranding();
            }

            renderLoader() {
                return (
                    <div className="branding">
                        <div className="branding__loader use-common-styles">
                            <Loader />
                        </div>
                    </div>
                );
            }

            render(): React.ReactNode {
                const { brandingLoading, brandingInfo } = this.props.ctrl;

                if (!brandingInfo || brandingLoading) {
                    return this.renderLoader();
                }

                return (
                    <div className={classNames('branding', brandingInfo.backgroundColor)}>
                        <Component brandingInfo={brandingInfo}/>
                    </div>
                );
            }
        }

        return inject(mapStoresToProps)(WithBranding) as IReactComponent;
    };
}

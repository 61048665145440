// eslint-disable-next-line no-use-before-define
import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';

import {IExpiredController} from './Expired.controller';

import {IBrandingInfo} from '@techsee/techsee-common/lib/constants/desktop-sharing.constants';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';

import './styles.scss';

export interface IExpiredStateProps {
    ctrl: IExpiredController,
    brandingInfo: IBrandingInfo
}

@observer
export class ExpiredState extends React.Component<IExpiredStateProps> {
    get logoUrl(): string {
        return this.props.brandingInfo.logo;
    }

    render(): React.ReactNode {
        const {brandingInfo} = this.props;
        const textLabelsComponent =
            (
                <div className="labels-container">
                    <SimpleLabel className="primary-label">
                        Your scheduled meeting is no longer available
                    </SimpleLabel>
                    <SimpleLabel className="secondary-label">
                        Thank you for using Visual Support
                    </SimpleLabel>
                </div>
            );

        return (
            <div className={classNames('page', brandingInfo.backgroundColor)}>
                <div className="page__logo">
                    <div className="logo_container">
                        <img src={this.logoUrl} alt="" />
                    </div>
                </div>

                <div className="page__content">
                    {textLabelsComponent}
                </div>

                <div className="powered-by">
                    Powered by TechSee
                </div>
            </div>
        );
    }
}

// eslint-disable-next-line no-use-before-define
import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {observer} from 'mobx-react';

import StartComponent from './states/Start/';
import ExpiredComponent from './states/Expired/';

import './app.scss';

@observer
export class AppComponent extends React.Component {

    render(): React.ReactNode {
        return (
            <Routes>
                <Route path="/" element={<StartComponent/>}/>
                <Route path="/expired" element={<ExpiredComponent/>}/>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        );
    }
}

import * as Sentry from '@sentry/browser';

//@ts-ignore
import {SENTRY_DOMAINS} from '@techsee/techsee-common/lib/constants/utils.constant';

try {
    if (ENABLE_SENTRY) {
        const domainMappings = SENTRY_DOMAINS;
        const SENTRY_HOST = 'sentry.io';

        const hostname = window.location.hostname;
        let sentryUrl = SENTRY_DSN;

        const domainPrefix = hostname.split('.')[0];

        if (domainMappings.hasOwnProperty(domainPrefix)) {
            sentryUrl = SENTRY_DSN.replace(SENTRY_HOST, domainMappings[domainPrefix]);
        }

        Sentry.init({
            dsn: sentryUrl
        });
    }
} catch (e) {
    console.error(e);
}

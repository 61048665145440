import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IExpiredController {
}

export class ExpiredController implements IExpiredController {

    constructor(
        private _browserUtilsService: IBrowserUtilsService,
    ) { }
}
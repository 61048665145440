/* eslint-disable global-require */
import {IAccountBrandingService} from '@techsee/techsee-client-infra/lib/services/AccountBrandingService';
import {DEFAULT_BRANDING, IBrandingInfo} from '@techsee/techsee-common/lib/constants/desktop-sharing.constants';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';

export interface IBrandingService {
    readonly guidPrefix: string,

    getAccountBranding(): Promise<IBrandingInfo>,
    getDefaultBranding(): IBrandingInfo
}

export class BrandingService implements IBrandingService {
    private _guidPrefix = '';

    constructor(
        private _iAccountBrandingService: IAccountBrandingService,
        private _subDomain: string
    ) {
        this._subDomain = _subDomain;
    }

    get guidPrefix(): string {
        return this._guidPrefix;
    }

    async getAccountBranding(): Promise<IBrandingInfo> {
        const data = await this._iAccountBrandingService.getAccountBrandingBySubDomain(this._subDomain, PlatformType.referralSite);

        const logo = data.find((item: any) => item.name === 'companyLogoImage')?.url;
        const loader = data.find((item: any) => item.name === 'loader')?.url;

        return {
            ...this.getDefaultBranding(),
            ...(logo && {logo}),
            ...(loader && {loader})
        };
    }

    getDefaultBranding(): IBrandingInfo {
        return {
            ...DEFAULT_BRANDING,
            logo: require('../_assets/images/techsee.png')
        };
    }
}

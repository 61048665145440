import {inject, IReactComponent} from 'mobx-react';

import { IAppStore } from '../../store';
import { ExpiredState, IExpiredStateProps } from './Expired.state';
import withBranding from '../../components/with-branding';

const mapStoresToProps = (stores: IAppStore, props: IExpiredStateProps) => ({
    ctrl: stores.expiredController,
    brandingInfo: props.brandingInfo,
});

export default withBranding(inject(mapStoresToProps)(ExpiredState)) as IReactComponent;

/* eslint-disable @typescript-eslint/no-explicit-any */
import EventEmitter from 'events';

import {WarmTransferRoomStatus} from '@techsee/techsee-common/lib/constants/room.constants';
import {EVENT_LEVELS, EVENT_SOURCES, LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';

import {IRoomService} from '@techsee/techsee-client-infra/lib/services/RoomService';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {IEventLogService} from '@techsee/techsee-client-infra/lib/services/EventLogService';

export interface IPendingRoomsService extends EventEmitter {
    pollRoomStatus(): Promise<void>
}

export class PendingRoomsService extends EventEmitter implements IPendingRoomsService {
    private _pollingAttemptsCounter = 0;

    constructor(
        private _browserUtilsService: IBrowserUtilsService,
        private _roomApiService: IRoomService,
        private _eventLogService: IEventLogService,
        private _pendingRoomGuid: string
    ) {
        super();

        setTimeout(() => this.pollRoomStatus(), 0);
    }

    async getRoomStatusByPrg(pendingRoomGuid: string): Promise<any> {
        return await this._roomApiService.roomStatusByPendingRoom(pendingRoomGuid);
    }

    async pollRoomStatus(): Promise<void> {
        try {
            if (this._pendingRoomGuid) {
                this.emit('pollingIsExecuted', true);

                this._pollingAttemptsCounter += 1;

                const roomDetails = await this.getRoomStatusByPrg(this._pendingRoomGuid);

                if (roomDetails.status === WarmTransferRoomStatus.EXPIRED || roomDetails.status === WarmTransferRoomStatus.READY) {
                    this.emit('pollingIsExecuted', false);
                    this.emit('roomStatusChanged', roomDetails);

                    return;
                }

                if (this._pollingAttemptsCounter === POLL_ROOM_STATUS_MAX_ATTEMPTS) {
                    this._pollingAttemptsCounter = 0;
                    this.emit('pollingIsExecuted', false);

                    return;
                }

                setTimeout(() => this.pollRoomStatus(), POLL_ROOM_STATUS_INTERVAL_MS);
            }
        } catch (err: any) {
            if (this._pollingAttemptsCounter === POLL_ROOM_STATUS_MAX_ATTEMPTS) {
                this._pollingAttemptsCounter = 0;
                this.emit('pollingIsExecuted', false);
            } else {
                setTimeout(() => this.pollRoomStatus(), POLL_ROOM_STATUS_INTERVAL_MS);
            }
        }
    }
}
